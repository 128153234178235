import React from "react"
import { graphql } from "gatsby"
import { createUseStyles } from 'react-jss'
import Layout from "../components/layout"
import SocialMedia from "../components/socialMedia"
import SEO from "../components/seo"

const useStyles = createUseStyles({
  introduction: {
    'text-align': 'center',
    'padding-top': '25%',
  },
  title: {
    'font-size': '70px',
    'line-height': '70px',
    'font-weight': 'bolder',
    'margin-top': 0
  },
  subheading: {
    'font-size': '25px',
    'line-height': '25px',
    'padding-bottom': '20px',
    'cursor': {
      show: 'false'
    },
  },
  socialMedia: {
    'font-size': '40px',
    'line-height': '40px',
  },
  icon: {
    'color': 'white',
    'margin': '10px 10px 0px 10px',
    '&:hover': {
      width: '50%',
      'border-bottom': '2px solid',
      background: 'transparent'
    }
  }
})

export default function Home({ data }) {
  const classes = useStyles()
  const metaData = data.site.siteMetadata
  return (
    <Layout style={classes.introduction} showFooter={false}>
        <SEO title="Home"/>
        <p className={classes.title}>{metaData.title}</p>
        <SocialMedia/>
    </Layout>
  )
}

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
                description
			}
		}
	}
`
